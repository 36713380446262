<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1"> Reset Password </b-card-title>
        <b-card-text class="mb-2">
          Your new password must be different from previously used passwords
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- password -->
            <Password v-model="password" />
            <Password
              name="Confirm Password"
              label="Confirm Password"
              rules="required|confirmed:Password"
              v-model="cPassword"
            />

            <!-- submit button -->
            <b-button v-if="!loading" block type="submit" variant="primary">
              Set New Password
            </b-button>
            <div v-else class="text-center">
              <b-spinner variant="primary" type="grow" label="Spinning" />
            </div>
          </b-form>
        </validation-observer>
        <b-card-text v-if="!loading" class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import Password from "@/components/util/Password.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import Auth from "@/services/auth.service.js";
import { mapMutations } from "vuex";

export default {
  components: {
    Password,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loading: false,
    cPassword: "",
    password: "",
    // validation
    required,
  }),
  methods: {
    ...mapMutations(["LOGOUT"]),
    validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          try {
            await Auth.updatePass(this.$route.params.token, this.password);
            this.LOGOUT();
            setTimeout(() => {
              this.$swal({
                icon: "success",
                title: "Good",
                text: "Your password has been updated successfully.",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }, 1000);
            this.$router.push({ name: "login" });
          } catch {
            this.$swal({
              icon: "error",
              title: "Faild..",
              text: "something went wrong",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
